import { addEventDelegate } from "@jeffcarbine/premmio/public/modules/eventDelegate/eventDelegate.js";
import { dataEmit } from "@jeffcarbine/premmio/public/modules/dataEmit/dataEmit.js";
import { isInViewport } from "@jeffcarbine/premmio/public/modules/isInViewport/isInViewport.js";
import { xhrForm } from "@jeffcarbine/premmio/public/modules/xhr/xhr.js";

// const setScrolledValue = (scrollPos) => {
//   const threshold = 200;

//   if (scrollPos > threshold) {
//     dataEmit("pageScrolled", true);
//   } else {
//     dataEmit("pageScrolled", false);
//   }
// };

//addEventDelegate("scroll", window, setScrolledValue);

const toggleOuterNav = () => {
  const outerNav = document.querySelector("#outerNav");

  outerNav.classList.toggle("open");
};

addEventDelegate("click", "button#navToggle", toggleOuterNav);

// parallax background
const backgroundParallax = (scroll) => {
  const diceY = scroll / 4,
    diceX = scroll / 8,
    moonsY = scroll / 5,
    moonsX = scroll / 10;

  document.documentElement.style.setProperty("--dice-y", `-${diceY}px`);
  document.documentElement.style.setProperty("--dice-x", `-${diceX}px`);
  document.documentElement.style.setProperty("--moons-y", `${moonsY}px`);
  document.documentElement.style.setProperty("--moons-x", `${moonsX}px`);
};

addEventDelegate("scroll", window, backgroundParallax);

const parallaxBackground = (scroll) => {
  // ge the parallax elements
  const parallaxElements = document.querySelectorAll(".parallax");

  // and then loop through them
  parallaxElements.forEach((element) => {
    // check the element's offset top
    const elementOffsetTop = element.offsetTop;

    // get the element height
    const elementHeight = element.offsetHeight;

    // get the enter point
    const enterPoint =
      elementOffsetTop >= window.innerHeight
        ? elementOffsetTop - window.innerHeight
        : 0;

    // get the exit point
    const exitPoint = elementOffsetTop + elementHeight;

    // get the pixel count
    const pixels = (scroll - enterPoint) / 4 + "px";

    element.style.setProperty("--parallax", pixels);
  });
};

addEventDelegate("scroll", window, parallaxBackground);

const enableParallax = (element) => {
  element.classList.add("parallax");
};

const disableParallax = (element) => {
  element.classList.remove("parallax");
};

isInViewport(
  ".category .background, .maps .background, section.map, article.map, section.parallaxBkg",
  enableParallax,
  disableParallax
);
